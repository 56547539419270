export default {
  data() {
    return {
      model: "",
      customData: []
    };
  },
  methods: {
    clearOldCustomData() {
      this.customData = this.customData.map(col => {
        col.value = null;
        return col;
      });
    },
    bindCustomData(model) {
      this.customData = this.customData.map(col => {
        if (col.type == "address") {
          col.value = {
            // number: "",
            street: "",
            city: "",
            state: "",
            postal_code: "",
            country: ""
          };
          let savedAddress = model?.custom_data?.[col.key];
          if (savedAddress) {
            col.value = { ...col.value, ...savedAddress };
          }
        } else if (col.type == "timerange") {
          col.value = {
            startTime: "",
            endTime: ""
          };
          let savedTimerange = model?.custom_data?.[col.key];
          if (savedTimerange) {
            col.value = { ...col.value, ...savedTimerange };
          }
        } else {
          // col.value = model?.custom_data?.[col.key];
          this.$set(col, "value", model?.custom_data?.[col.key]);
        }
        return col;
      });
    },
    setExtraSchema(data) {
      this.customData = data?.schema?.columns.map(col => {
        col.isCustomData = true;
        if (col.type == "address") {
          col.value = {
            // number: "",
            street: "",
            city: "",
            state: "",
            postal_code: "",
            country: ""
          };
          let savedAddress = this[this.model]?.custom_data?.[col.key];
          if (savedAddress) {
            col.value = { ...col.value, ...savedAddress };
          }
        } else if (col.type == "timerange") {
          col.value = {
            startTime: "",
            endTime: ""
          };
          let savedTimerange = this[this.model]?.custom_data?.[col.key];
          if (savedTimerange) {
            col.value = { ...col.value, ...savedTimerange };
          }
        } else {
          this.$set(col, "value", this[this.model]?.custom_data?.[col.key]);
          // col.value = this[this.model]?.custom_data?.[col.key];
        }
        return col;
      });

      if (this.customData.length > 0) {
        this.formData[this.formData.length - 1].fields = this.formData[
          this.formData.length - 1
        ].fields.concat(this.customData);
      }
      if (this.$route.params.id == "new") {
        this.clearOldCustomData();
        // reactivity workaround or else it doesnt bind correctly for deep nested items
        this.bindCustomData();
      }
    },
    getSettings(setting, model) {
      this.model = model;
      const { setExtraSchema, $store } = this;
      $store.dispatch("settings/getSettings", {
        setting,
        cb: setExtraSchema
      });
    }
  }
};
