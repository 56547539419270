<template>
  <div class="fleetDetail">
    <div class="fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div class="flex flex-wrap justify-between px-8 md:px-14 w-full">
      <div class="w-full lg:w-3/5">
        <SlideUp>
          <Form
            :form="formData"
            :formName="$t('supplier detail')"
            :options="options"
          >
            <template v-slot:aftername v-if="$route.params.id != 'new'">
              <span
                @click="handleDelete"
                class="text-rose-500 border-b-4 border-rose-500 cursor-pointer text-xs"
              >
                {{ $t("delete") }}
              </span>
            </template>
          </Form>
        </SlideUp>
      </div>
      <div class="w-full lg:w-2/5 lg:pl-8">
        <SlideUp v-if="$route.params.id != 'new'">
          <div
            class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-blueGray-100 border-0"
          >
            <div class="rounded-t-lg bg-white mb-0 px-6 py-6">
              <div class="text-center flex justify-between">
                <h6 class="text-blueGray-700 text-xl font-bold">
                  {{ $t("documents") }}
                </h6>
                <input
                  id="uploadFile"
                  type="file"
                  ref="uploadFile"
                  @change="fileChange"
                  accept="*"
                  multiple
                  hidden
                />
                <label
                  class="bg-emerald-500 cursor-pointer text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  for="uploadFile"
                >
                  {{ $t("upload") }}
                </label>
              </div>
            </div>

            <div class="">
              <div
                class="border-b px-6 py-4 flex justify-between"
                :class="{
                  'border-t': index == 0,
                  'bg-white': index % 2 != 0
                }"
                v-for="(u, index) in supplier.uploads"
                :key="`${u.id}-uploaded-items`"
              >
                <a class="block font-semibold" :href="u.path">
                  {{ u.filename }}
                </a>
                <span>
                  {{ u.content_type }}
                </span>
              </div>
            </div>
          </div>
        </SlideUp>

        <SlideUp v-if="$route.params.id != 'new'">
          <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0 mt-6"
          >
            <div class="rounded-t bg-white mb-0 px-6 py-6">
              <div class="text-center flex justify-between items-center">
                <h6 class="text-blueGray-700 font-semibold">
                  {{ $t("contacts") }}
                </h6>
                <button
                  class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs ml-4 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  @click="newContact"
                >
                  {{ $t("add new") }}
                </button>
              </div>
            </div>
            <div class="mb-0 px-6 pb-6">
              <div v-if="supplier.contacts && supplier.contacts.length == 0">
                {{ $t("no contacts added yet") }}
              </div>
              <div class="overflow-auto w-full" v-else>
                <table
                  class="items-center w-full bg-transparent border-collapse"
                >
                  <thead>
                    <tr>
                      <th
                        class="sticky top-0 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        v-for="c in contactKeys"
                        :key="`${c}-th`"
                      >
                        {{ c }}
                      </th>
                      <th
                        class="sticky top-0 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      >
                        {{ $t("action") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(contact, i) in supplier.contacts"
                      :key="`${i}-${contact.name}`"
                      :class="{ 'bg-blueGray-100': i % 2 != 0 }"
                    >
                      <td
                        v-for="c in contactKeys"
                        :key="c"
                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                        <span
                          class="bg-blue-600 rounded px-2 py-1 text-blue-50"
                          v-if="c == 'default' && contact[c] == true"
                        >
                          <i class="fas fa-user-check" />
                        </span>
                        <span v-if="c != 'default'">
                          {{ contact[c] }}
                        </span>
                      </td>
                      <td
                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                        <i
                          class="far fa-edit ml-2"
                          @click="editContact(contact, i)"
                        />
                        <el-popconfirm
                          :title="$t('Are you sure to delete this?')"
                          @confirm="removeContact(i)"
                        >
                          <i
                            slot="reference"
                            class="far fa-trash-alt text-rose-500 ml-2"
                          />
                        </el-popconfirm>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </SlideUp>
      </div>
    </div>
    <Modal v-if="contactForm" @closeModal="contactForm = false">
      <div class="bg-gray-100 px-6 py-3">
        <div class="relative w-full mb-4" v-for="c in contactKeys" :key="c">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold"
            for=""
          >
            {{ $t(c) }}
          </label>
          <input
            v-if="c != 'default'"
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-800 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            v-model="selectedContact[c]"
          />
          <el-switch v-if="c == 'default'" v-model="selectedContact.default" />
        </div>
        <button
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs mt-6 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          :class="{
            'cursor-not-allowed': selectedContact.name == ''
          }"
          @click="handleSave"
          :disabled="selectedContact.name == ''"
        >
          {{ $t("save") }}
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import SlideUp from "@/components/transitions/SlideUp.vue";
import Modal from "../components/Modal.vue";
import Form from "@/components/Form.vue";
import { mapState } from "vuex";
import formatting from "../mixins/formatting";
import { bindData } from "../helpers/form";
import customData from "../mixins/customData";
export default {
  name: "SupplierDetail",
  components: {
    SlideUp,
    Form,
    Modal
  },
  mixins: [formatting, customData],
  computed: {
    ...mapState("suppliers", ["supplier"]),
    options() {
      return {
        types: this.$store.state.typeDefinitions.supplier
      };
    }
  },
  data() {
    const isUpdate = this.$route.params.id != "new";

    return {
      selectedContactIndex: null,
      selectedContact: {
        name: "",
        email: "",
        number: "",
        position: "",
        default: true
      },
      contactForm: false,
      contactKeys: ["name", "email", "number", "position", "default"],
      formData: [
        {
          section: "Supplier Information",
          dispatch: isUpdate
            ? "suppliers/updateSupplier"
            : "suppliers/createSupplier",
          fields: [
            { key: "id", name: "id", type: "hidden", value: "" },
            {
              key: "name",
              name: "name",
              type: "text",
              value: "",
              width: "full"
            },
            {
              name: "type",
              key: "type_id",
              value: "",
              type: "selectByOptionProp",
              options: "types",
              optionLabelKey: "name"
            },
            // {
            //   parentKey: "address",
            //   key: "number",
            //   name: "number",
            //   type: "text",
            //   value: ""
            // },
            {
              parentKey: "address",
              key: "street",
              name: "street",
              type: "text",
              value: ""
            },
            {
              parentKey: "address",
              key: "city",
              name: "city",
              type: "text",
              value: ""
            },
            {
              parentKey: "address",
              key: "state",
              name: "state",
              type: "text",
              value: ""
            },
            {
              parentKey: "address",
              key: "postal_code",
              name: "postal_code",
              type: "text",
              value: ""
            },
            {
              parentKey: "address",
              key: "country",
              name: "country",
              type: "text",
              value: ""
            },
            { key: "notes", name: "notes", type: "textarea", value: "" }
          ]
        }
      ]
    };
  },
  watch: {
    supplier() {
      bindData(this.formData, this.supplier);
    }
  },
  methods: {
    handleSave() {
      let contacts = JSON.parse(JSON.stringify(this.supplier.contacts));
      if (this.selectedContact.default == true) {
        contacts = contacts.map((contact, idx) => {
          if (idx != this.selectedContactIndex) {
            contact.default = false;
          }
          return contact;
        });
      } else if (this.selectedContact.default == false) {
        if (
          contacts.length > 0 &&
          !contacts.find(
            (contact, idx) =>
              idx != this.selectedContactIndex && contact.default
          )
        ) {
          this.selectedContact.default = true;
        }
      }
      if (this.selectedContactIndex == null) {
        contacts.push(this.selectedContact);
      } else {
        contacts[this.selectedContactIndex] = this.selectedContact;
      }
      this.$store.dispatch("suppliers/updateSupplier", {
        contacts: [...contacts],
        id: this.$route.params.id
      });
      // reset form
      this.newContact();
      this.contactForm = false;
    },
    editContact(contact, index) {
      this.contactForm = true;
      this.selectedContactIndex = index;
      this.selectedContact = {
        name: contact.name,
        email: contact.email,
        number: contact.number,
        position: contact.position,
        default: contact.default
      };
    },
    newContact() {
      this.contactForm = true;
      this.selectedContactIndex = null;
      this.selectedContact = {
        name: "",
        email: "",
        number: "",
        position: "",
        default: false
      };
    },
    removeContact(index) {
      let contacts = JSON.parse(JSON.stringify(this.supplier.contacts));
      contacts = contacts.filter((_, i) => i != index);
      if (contacts.length > 0 && !contacts.find(contact => contact.default)) {
        contacts[0].default = true;
      }
      this.$store.dispatch("suppliers/updateSupplier", {
        contacts: [...contacts],
        id: this.$route.params.id
      });
    },
    handleDelete() {
      const deleteString = `${this.$t("Confirm delete supplier")}: ${
        this.supplier.name
      }`;
      this.$confirm(deleteString).then(() => {
        this.$store.dispatch("suppliers/deleteSupplier", {
          supplier: this.supplier
        });
      });
    },
    fileChange() {
      let formData = new FormData();
      formData.append("file", this.$refs.uploadFile.files[0]);
      formData.append(`action`, "supplier_docs");
      formData.append(`supplier_id`, this.supplier.id);
      this.$store.dispatch("upload/upload", {
        payload: formData,
        cb: this.getSupplierData
      });
    },
    getSupplierData() {
      this.$store.dispatch("suppliers/getSupplier", this.$route.params.id);
    }
  },
  created() {
    if (this.$route.params.id != "new") {
      this.getSupplierData();
    }
    this.$store.dispatch("typeDefinitions/getAllSupplierDefinitions");
    this.getSettings("supplier", "supplier");
  }
};
</script>
